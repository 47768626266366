/** What's Weird: Projector **/

import React from "react";
import { gsap, Quad } from "gsap/all"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { positionText } from "../../helpers"
import SlideshowController from "../../components/media/slideshow-controller";

let globalState, data, preloadData, slideshowData, autoAdvanceInterval, autoAdvanceTO;
let slideSound;
let firstSlide = true;

//start the blur at 0 pixels
let blurElement = {a:10};

let slideTweens = [4, 26, 4, 4, 5], tl;

class WhatsWeirdPage2 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[2];
    preloadData = globalState.assets[3];

    firstSlide = true;

    slideshowData = [
      {
        file: globalState.cdnUrl + data.slide1,
        highlight: globalState.cdnUrl + data.slide1Highlight,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide2,
        highlight: globalState.cdnUrl + data.slide2Highlight,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide3,
        highlight: globalState.cdnUrl + data.slide3Highlight,
        fileType: 'image',
      },
      {
        file: globalState.cdnUrl + data.slide4,
        highlight: globalState.cdnUrl + data.slide4Highlight,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide5,
        highlight: globalState.cdnUrl + data.slide5Highlight,
        fileType: 'image'
      }
    ]

    //refs
    this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
    currentSlide: 1,
    autoAdvance: 8000,
    styles: {
      top: 0,
      left: 0
    }
  }


  componentDidMount = () => {

    //Set autoAdvance
    this.setAutoAdvance();

    //position text
    positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);

    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

    //add page base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1); //Should be fixed

    //update volume of base track
    globalState.baseAudioRef.updateVolume(2, 90);

    //Update dimensions
    this.updateDimensions();

    //Add resize listener
    window.addEventListener("resize", this.updateDimensions);


    
    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.slide1Poster,
      globalState.cdnUrl + preloadData.slide2,
      globalState.cdnUrl + preloadData.slide3,
      globalState.cdnUrl + preloadData.slide4,
      globalState.cdnUrl + preloadData.slide5,
      globalState.cdnUrl + preloadData.slide6Poster,
      globalState.cdnUrl + preloadData.slide7,
      globalState.cdnUrl + preloadData.slide8,
      globalState.cdnUrl + preloadData.slide9,
      globalState.cdnUrl + preloadData.slide10,
      globalState.cdnUrl + preloadData.slide11,
      globalState.cdnUrl + preloadData.slide12,
    ], this.preloadCallback);

    //update the carousel 
    setTimeout(()=>{
      this.updateCurrentSlide(this.state.currentSlide);
      //blur in slide 1

      
      slideSound = new Audio();
      slideSound.src = globalState.cdnUrl + data.slideSound1;
      if(!globalState.baseAudioRef.isMuted()){
        slideSound.play();
      }

      gsap.to(blurElement, 1, {a:0, onUpdate:this.applyBlur});
      gsap.set(this.refs.s1Text, {y:"20px"});
      gsap.to(this.refs.s1Text, 0.2, {y:"0", delay:1});
      gsap.to(this.refs.s1Text, 0.4, {scale:(0.9)});
      gsap.to(this.refs.s1Text, 0.4, {scale:(1), delay:0.4});
      gsap.to(this.refs.s1Text, 0.5, {scale:(1), delay:0.4});

    },globalState.transitionDuration);
  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  componentDidUpdate() {
    //console.log("componentDidUpdate");
    //this.updateCurrentSlide(this.state.currentSlide);
  }


  componentWillUnmount() {
    
    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    this.resetTweens(this.state.currentSlide);
    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);

    //remove event listeners
    window.removeEventListener("resize", this.updateDimensions);

    //Clear AutoAdvanceInterval
    clearInterval(autoAdvanceInterval);

    clearTimeout(autoAdvanceTO);
  }




  isTransitioning = () => {
    //kill tweens
  }

  updateDimensions = () => {
    console.log('updateDimensions');
    //scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 780);


    let el = this.refs.projectorWrapperRef;
    let text = this.refs.projectorTextRef;
    let availableWidth = window.innerWidth - 400;
    let availableHeight = window.innerHeight;
    let contentWidth = 912;
    let contentHeight = 692;
    let maxWidth = availableWidth - 300;
    let s = availableWidth / contentWidth;
    //if too short
    if (contentHeight * s < availableHeight) {
      //s = availableHeight / contentHeight;
    }
    //let s = availableHeight / contentHeight;
    console.log("s: " + s + ", maxWidth: " + maxWidth);
    if (s > 1.23) {
      s = 1.23;
    }
    let mr = (availableWidth - contentWidth * s + 50) + "px";


    if (el) {
      gsap.to(el, 0, { scale: s });
      gsap.to(text, 0, { marginRight: mr });

    }



  }


  //Set AutoAdvance
  setAutoAdvance = () => {
    if (this.state.autoAdvance) {
      autoAdvanceInterval = setInterval(() => {
        this.animateSlideOut(this.state.currentSlide);

        autoAdvanceTO = setTimeout(()=>{
          let nextSlide = this.state.currentSlide + 1;
          this.resetTweens(this.state.currentSlide);
          if (nextSlide > slideshowData.length) {
            nextSlide = 1;
          }
      
          this.setState({ currentSlide: nextSlide });
          this.updateCurrentSlide(nextSlide);
          //this.stopAutoAdvance();
        }, 500);
        
      }, this.state.autoAdvance);
    }
  }

  //Stop AutoAdvance
  stopAutoAdvance = () => {
    this.setState({ autoAdvance: false });
    clearInterval(autoAdvanceInterval);
  }

  //Slideshow controls
  handleNextClick = () => {
    console.log('handleNextClick: ' + this.state.currentSlide);
    this.animateSlideOut(this.state.currentSlide);
    setTimeout(()=>{
      let nextSlide = this.state.currentSlide + 1;
      this.resetTweens(this.state.currentSlide);
      if (nextSlide > slideshowData.length) {
        nextSlide = 1;
      }
  
      this.setState({ currentSlide: nextSlide });
      this.updateCurrentSlide(nextSlide);
      this.stopAutoAdvance();
    }, 500);

  }

  handleBackClick = () => {
    this.animateSlideOut(this.state.currentSlide);
    setTimeout(()=>{
      let nextSlide = this.state.currentSlide - 1;
      this.resetTweens(this.state.currentSlide);
      if (nextSlide < 1) {
        nextSlide = slideshowData.length;
      }

      this.setState({ currentSlide: nextSlide });
      this.updateCurrentSlide(nextSlide);
      this.stopAutoAdvance();
    }, 500);
  }

  resetTweens = (num) => {
     //reset highlights
     tl.pause();
     for(var i=1; i<=slideTweens[num-1]; i++){
        gsap.killTweensOf(this.refs["s"+num+"r"+i]);
        gsap.set(this.refs["s"+num+"r"+i], {scaleX: 0});
    }
  }

  animateSlideIn = (num) => {
    slideSound = new Audio();
    let animationSpeed = 0.5;
    switch(num){
      case 1:
        slideSound.src = globalState.cdnUrl + data.slideSound2;
        gsap.fromTo(this.refs["slide"+num], animationSpeed, {rotate:"-45deg", x:"1216px", y:"857px"}, {rotate:"0deg", x:"0", y:"0"});
        break;
      case 2:
        slideSound.src = globalState.cdnUrl + data.slideSound3;
        gsap.fromTo(this.refs["slide"+num], animationSpeed, {rotate:"45deg", x:"1568px", y:"932px"}, {rotate:"0deg", x:"50", y:"4"});
        gsap.to(this.refs["slide"+num], 0.3, {rotate:"0", x:"0", y:"0", delay:animationSpeed+0.1});
        break;
      case 3:
        slideSound.src = globalState.cdnUrl + data.slideSound4;
        gsap.fromTo(this.refs["slide"+num], animationSpeed, {rotate:"-55deg", x:"1383px", y:"488px"}, {rotate:"0deg", x:"43", y:"46"});
        gsap.to(this.refs["slide"+num], 0.4, {rotate:"0", x:"0", y:"0", delay:animationSpeed+0.1});

        break;
      case 4:
        slideSound.src = globalState.cdnUrl + data.slideSound1;
        gsap.fromTo(this.refs["slide"+num], animationSpeed, {rotate:"-45deg", x:"1593px", y:"-335px"}, {rotate:"2deg", x:"42", y:"-58"});
        gsap.to(this.refs["slide"+num], 0.4, {rotate:"0", x:"0", y:"0", delay:animationSpeed+0.1});
        break;
      case 5:
        slideSound.src = globalState.cdnUrl + data.slideSound6;
        gsap.fromTo(this.refs["slide"+num], animationSpeed, {rotate:"45deg", x:"1492px", y:"371px"}, {rotate:"12deg", x:"20", y:"72"});
        gsap.to(this.refs["slide"+num], 0.4, {rotate:"0", x:"0", y:"0", delay:animationSpeed+0.1});
        break;
      default:
        break;
    }
    if(!globalState.baseAudioRef.isMuted()){
      slideSound.play();
    }
  }

  animateSlideOut = (num) => {
    switch(num){
      case 1:
        gsap.fromTo(this.refs["slide"+num], {rotate:"0deg", x:"0", y:"0"}, {rotate:"-45deg", x:"-1092px", y:"-137px"});
        break;
      case 2:
        gsap.fromTo(this.refs["slide"+num], {rotate:"0deg", x:"0", y:"0"}, {rotate:"-45deg", x:"-1092px", y:"-137px"});
        break;
      case 3:
        gsap.fromTo(this.refs["slide"+num], {rotate:"0deg", x:"0", y:"0"}, {rotate:"-45deg", x:"-1092px", y:"-137px"});
        break;
      case 4:
        gsap.fromTo(this.refs["slide"+num], {rotate:"0deg", x:"0", y:"0"}, {rotate:"-45deg", x:"-1092px", y:"-137px"});
        break;
      case 5:
        gsap.fromTo(this.refs["slide"+num], {rotate:"0deg", x:"0", y:"0"}, {rotate:"-45deg", x:"-1092px", y:"-137px"});
        break;
      default:
        break;
    }
  }

  updateCurrentSlide = (num, overrideCurrentState = false) => {
    console.log("updateCurrentSlide: " + num + ", firstSlide: " + this.state.firstSlide)

    if(firstSlide){
      setTimeout(()=>{
        firstSlide = false;
      }, 200);
    } else {
      this.animateSlideIn(num);
    }

    setTimeout(()=>{
      this.animateHighlight(num);
    }, 1000);
    
    for (let i = 1; i <= 5; i++) {
      try{

        this.refs['slide' + i].classList.remove('show');
        if(overrideCurrentState && i === num){   
            //override currentSlide state property
            this.refs['slide' + i].classList.add('show');
        } else if (i === this.state.currentSlide) {
            this.refs['slide' + i].classList.add('show');
        }
      } catch(e){
        console.warn(e);
      }
    }
  }

  animateHighlight = (num) => {
    tl = gsap.timeline({});
    
    console.log("animate highlight: " + num);
    
    switch(num) {

      case 1:
        
        tl.add(gsap.to(this.refs.s1r1, 0.7, {scaleX: 1, ease:Quad.easeOut, delay:1.3}));
        tl.add(gsap.to(this.refs.s1r3, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:0.2}));
        tl.add(gsap.to(this.refs.s1r2, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:0.2}));
        tl.add(gsap.to(this.refs.s1r4, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.2}));
        tl.add(gsap.to(this.refs.s1r5, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.2}));
        break;

      case 2:
        gsap.to(this.refs.s2r1, 0.7, {scaleX: 1, ease:Quad.easeOut, delay:0.6});

        gsap.to(this.refs.s2r2, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:1});
        gsap.to(this.refs.s2r4, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:1.2});
        gsap.to(this.refs.s2r6, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:1.4});
        gsap.to(this.refs.s2r8, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:1.8});

        gsap.to(this.refs.s2r10, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:2.6});
        gsap.to(this.refs.s2r12, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:2.4});
        gsap.to(this.refs.s2r14, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:2.2});

        gsap.to(this.refs.s2r16, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:3});
        gsap.to(this.refs.s2r18, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:3});
        gsap.to(this.refs.s2r20, 0.4, {scaleX: 1, ease:Quad.easeOut, delay:3.2});
        gsap.to(this.refs.s2r22, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:3.4});
        gsap.to(this.refs.s2r24, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:3.6});
        
        gsap.to(this.refs.s2r3, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:1.2});
        gsap.to(this.refs.s2r5, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:1.6});
        gsap.to(this.refs.s2r7, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:1.8});
        gsap.to(this.refs.s2r9, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:2});
        gsap.to(this.refs.s2r11, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:2.6});
        gsap.to(this.refs.s2r13, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:2.8});
        gsap.to(this.refs.s2r15, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:3});
        gsap.to(this.refs.s2r17, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:3.2});
        gsap.to(this.refs.s2r19, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:3.4});
        gsap.to(this.refs.s2r21, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:3.6});
        gsap.to(this.refs.s2r23, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:3.4});
        gsap.to(this.refs.s2r25, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:3.2});
        gsap.to(this.refs.s2r26, 0.3, {scaleX: 1, ease:Quad.easeOut, delay:3.4});
        break;

      case 3:
        console.log('case 3')
        console.log(this.refs.s3r1);
        tl.add(gsap.to(this.refs.s3r1, 0.7, {scaleX: 1, ease:Quad.easeOut, delay:0.6}));
        tl.add(gsap.to(this.refs.s3r2, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.3}));
        tl.add(gsap.to(this.refs.s3r3, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.3}));
        tl.add(gsap.to(this.refs.s3r4, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.3}));
        break;

      case 4:
        tl.add(gsap.to(this.refs.s4r1, 0.7, {scaleX: 1, ease:Quad.easeOut, delay:0.6}));
        tl.add(gsap.to(this.refs.s4r2, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.3}));
        tl.add(gsap.to(this.refs.s4r3, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.3}));
        tl.add(gsap.to(this.refs.s4r4, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.3}));
        break;

      case 5:
        tl.add(gsap.to(this.refs.s5r1, 0.7, {scaleX: 1, ease:Quad.easeOut, delay:0.6}));
        tl.add(gsap.to(this.refs.s5r2, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.3}));
        tl.add(gsap.to(this.refs.s5r3, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.3}));
        tl.add(gsap.to(this.refs.s5r4, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.3}));
        tl.add(gsap.to(this.refs.s5r5, 0.5, {scaleX: 1, ease:Quad.easeOut, delay:0.3}));
        break;

      default :
        console.log("animation default case")
      break;

    }
  }

  //here you pass the filter to the DOM element
  applyBlur = () => {
      gsap.set(this.refs.s1Text, {webkitFilter:"blur(" + blurElement.a + "px)",filter:"blur(" + blurElement.a + "px)"});  
  };


  render() {

    return (

      <>

        <link rel="prefetch" href="/whats-weird/3" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/whats-weird/3" prevLocation="/whats-weird/1"
          isTransitioning={this.isTransitioning} />

        <div className="fullpage-wrapper">
          <SEO title="Pine Point - What's Weird" />

          <div className="text-wrapper">
            <div className="text text--right-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginTop: "20px" }} alt="The government produces documents and forms you didn’t know existed – attempts to address the ‘situation’. Pine Pointers got letters telling them their hometown would have its name removed from the map." ref="projectorTextRef" />
              <SlideshowController xPos="0" yPos="430px" handleNextClick={this.handleNextClick} handleBackClick={this.handleBackClick} state={this.state} totalSlides={slideshowData.length} />
            </div>
          </div>


          <div className="projector-bg" ref="fullscreenBgRef" >
            <div ref="projectorWrapperRef" className="projector-wrapper" >
              <div className="projector-bg-white"></div>
              <img src={globalState.cdnUrl + data.background} alt="" className="projector-mask" />
              <div className="carousel-wrapper" style={{ top: '20px', left: '15px', position: 'absolute', width: '883px', height: '652px', overflow: 'hidden' }}>
                {/* <Carousel ref="carouselRef" data={slideshowData} globalState={globalState} fullscreen={false} position={[0, 0]} size={["883px", "652px"]} state={this.state} /> */}
                <ul className="carousel" style={{left: 0, top: 0, width: "883px", height: "652px"}}>
                  <li className="slide slide1" ref="slide1">
                    <img ref="s1Text" className="text" src={globalState.cdnUrl + data.slide1} alt="" />    
                    <svg className="highlight" viewBox="0 0 883 652">
                      <mask id="slide1-mask" width="883" height="652">
                        <rect ref="s1r1" width="515" height="72" x="284" y="130" style={{fill:"white"}} />
                        <rect ref="s1r2" width="473" height="32" x="23" y="260" style={{fill:"white"}} />
                        <rect ref="s1r3" width="371" height="37" x="503" y="242" style={{fill:"white"}} />
                        <rect ref="s1r4" width="263" height="34" x="255" y="339" style={{fill:"white"}} />
                        <rect ref="s1r5" width="564" height="36" x="20" y="379" style={{fill:"white"}} />
                      </mask>
                      <image mask="url(#slide1-mask)" width="883" height="652" xlinkHref={globalState.cdnUrl + data.slide1Highlight} alt="highlight" />    
                      <image mask="url(#slide1-mask)" width="883" height="652" xlinkHref={globalState.cdnUrl + data.slide1Highlight} alt="highlight" />    
                    </svg>
                  </li>
                  <li className="slide slide2" ref="slide2">
                    <img ref="s2Text" className="text" src={globalState.cdnUrl + data.slide2} alt="" />    
                    <svg className="highlight" width="883" height="652" viewBox="0 0 883 652">
                      <mask id="slide2-mask">
                        <rect ref="s2r1" width="433" height="36" x="281" y="-4px" style={{fill:"white"}} />

                        <rect ref="s2r2" width="167" height="31" x="69" y="244" style={{fill:"white"}} />
                        <rect ref="s2r3" width="40" height="34" x="668" y="246" style={{fill:"white"}} />
                        
                        <rect ref="s2r4" width="206" height="32" x="66" y="275" style={{fill:"white"}} />
                        <rect ref="s2r5" width="35" height="28" x="666" y="276" style={{fill:"white"}} />
                        
                        <rect ref="s2r6" width="169" height="27" x="72" y="303" style={{fill:"white"}} />
                        <rect ref="s2r7" width="46" height="29" x="667" y="298" style={{fill:"white"}} />

                        <rect ref="s2r8" width="136" height="21" x="76" y="326" style={{fill:"white"}} />
                        <rect ref="s2r9" width="39" height="24" x="674" y="324" style={{fill:"white"}} />

                        <rect ref="s2r10" width="223" height="29" x="71" y="341" style={{fill:"white"}} />
                        <rect ref="s2r11" width="53" height="35" x="667" y="345" style={{fill:"white"}} />

                        <rect ref="s2r12" width="343" height="42" x="73" y="353" style={{fill:"white"}} />
                        <rect ref="s2r13" width="39" height="31" x="665" y="368" style={{fill:"white"}} />

                        <rect ref="s2r14" width="392" height="33" x="74" y="393" style={{fill:"white"}} />
                        <rect ref="s2r15" width="30" height="32" x="679" y="389" style={{fill:"white"}} />

                        <rect ref="s2r16" width="458" height="52" x="67" y="414" style={{fill:"white"}} />
                        <rect ref="s2r17" width="49" height="37" x="676" y="404" style={{fill:"white"}} />

                        <rect ref="s2r18" width="290" height="35" x="66" y="460" style={{fill:"white"}} />
                        <rect ref="s2r19" width="57" height="34" x="667" y="438" style={{fill:"white"}} />

                        <rect ref="s2r20" width="430" height="34" x="71" y="488" style={{fill:"white"}} />
                        <rect ref="s2r21" width="49" height="34" x="669" y="469" style={{fill:"white"}} />

                        <rect ref="s2r22" width="501" height="33" x="140" y="519" style={{fill:"white"}} />
                        <rect ref="s2r23" width="47" height="34" x="668" y="513" style={{fill:"white"}} />

                        <rect ref="s2r24" width="402" height="37" x="71" y="540" style={{fill:"white"}} />
                        <rect ref="s2r25" width="37" height="31" x="673" y="545" style={{fill:"white"}} />

                        <rect ref="s2r26" width="42" height="18" x="670" y="502" style={{fill:"white"}} />

                      </mask>
                      <image mask="url(#slide2-mask)" width="883" height="652" xlinkHref={globalState.cdnUrl + data.slide2Highlight} alt="highlight" />    
                      <image mask="url(#slide2-mask)" width="883" height="652" xlinkHref={globalState.cdnUrl + data.slide2Highlight} alt="highlight" />    
                    </svg>                    
                  </li>
                  <li className="slide slide3" ref="slide3">
                    <img ref="s3Text" className="text" src={globalState.cdnUrl + data.slide3} alt="" />    
                    <svg className="highlight" width="883" height="652" viewBox="0 0 883 652">
                      <mask id="slide3-mask">
                        <rect ref="s3r1" width="222" height="45" x="41" y="-8" style={{fill:"white"}} />
                        <rect ref="s3r2" width="617" height="30" x="206" y="287" style={{fill:"white"}} />
                        <rect ref="s3r3" width="779" height="26" x="51" y="319" style={{fill:"white"}} />
                        <rect ref="s3r4" width="567" height="30" x="49" y="350" style={{fill:"white"}} />
                      </mask>
                      <image mask="url(#slide3-mask)" width="883" height="652" xlinkHref={globalState.cdnUrl + data.slide3Highlight} alt="highlight" />    
                      <image mask="url(#slide3-mask)" width="883" height="652" xlinkHref={globalState.cdnUrl + data.slide3Highlight} alt="highlight" />    
                    </svg>
                  </li>
                  <li className="slide slide4" ref="slide4">
                    <img ref="s4Text" className="text" src={globalState.cdnUrl + data.slide4} alt="" />    
                    <svg className="highlight" width="883" height="652" viewBox="0 0 883 652">
                      <mask id="slide4-mask">
                        <rect ref="s4r1" width="319" height="35" x="36" y="127" style={{fill:"white"}} />
                        <rect ref="s4r2" width="627" height="32" x="184" y="347" style={{fill:"white"}} />
                        <rect ref="s4r3" width="774" height="40" x="37" y="380" style={{fill:"white"}} />
                        <rect ref="s4r4" width="124" height="30" x="38" y="422" style={{fill:"white"}} />
                      </mask>
                      <image mask="url(#slide4-mask)" width="883" height="652" xlinkHref={globalState.cdnUrl + data.slide4Highlight} alt="highlight" />    
                      <image mask="url(#slide4-mask)" width="883" height="652" xlinkHref={globalState.cdnUrl + data.slide4Highlight} alt="highlight" />    
                    </svg>
                  </li>
                  <li className="slide slide5" ref="slide5">
                    <img ref="s5Text" className="text" src={globalState.cdnUrl + data.slide5} alt="" />    
                    <svg className="highlight" width="883" height="652" viewBox="0 0 883 652">
                      <mask id="slide5-mask">
                        <rect ref="s5r1" width="422" height="36" x="78" y="58" style={{fill:"white"}} />
                        <rect ref="s5r2" width="734" height="28" x="77" y="117" style={{fill:"white"}} />
                        <rect ref="s5r3" width="306" height="30" x="69" y="145" style={{fill:"white"}} />
                        <rect ref="s5r4" width="360" height="22" x="467" y="449" style={{fill:"white"}} />
                        <rect ref="s5r5" width="603" height="34" x="63" y="472" style={{fill:"white"}} />
                      </mask>
                      <image mask="url(#slide5-mask)" width="883" height="652" xlinkHref={globalState.cdnUrl + data.slide5Highlight} alt="highlight" />    
                      <image mask="url(#slide5-mask)" width="883" height="652" xlinkHref={globalState.cdnUrl + data.slide5Highlight} alt="highlight" />    
                    </svg>
                  </li>
                </ul>
              </div>
            </div>



          </div>

        </div>

      </>
    );
  }


};

export default WhatsWeirdPage2
